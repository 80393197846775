// src/components/Loading.js
import React from 'react';
import './Loading.css';

const Loading = () => {
    return (
        <div className="spinner">
            <svg viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2"></circle>
                <defs>
                    <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
                        <stop offset="0%" stopColor="#5e5404"/>
                        <stop offset="100%" stopColor="#2D283E"/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};

export default Loading;
