import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
    const handleNavClick = () => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
        }
    };
    return (
        <div className="navbar">
            <NavLink onClick={handleNavClick} to="/ref" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>
                <img src="/icons/ref.png" alt="Ref" className="nav-icon" />
                <span>Ref</span>
            </NavLink>
            <NavLink onClick={handleNavClick} to="/task" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>
                <img src="/icons/task.png" alt="Task" className="nav-icon" />
                <span>Task</span>
            </NavLink>
            <NavLink onClick={handleNavClick} to="/" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>
                <img src="/icons/tap.png" alt="Tap" className="nav-icon" />
                <span>Tap</span>
            </NavLink>
            <NavLink onClick={handleNavClick} to="/boost" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>
                <img src="/icons/boost.png" alt="Boost" className="nav-icon" />
                <span>Boost</span>
            </NavLink>
            <NavLink onClick={handleNavClick} to="/wheel" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>
                <img src="/icons/stats.png" alt="Stats" className="nav-icon" />
                <span>Stats</span>
            </NavLink>
        </div>
    );
};

export default Navbar;
