import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import './Wheel.css'; // Ensure CSS matches your design
import {Wheel} from 'react-custom-roulette';
import {TonConnectButton, useTonAddress, useTonWallet, useTonConnectUI} from "@tonconnect/ui-react";

const data = [
    { option: '$10,000 Cash', prizeKey: 'cash_10000', probability: 0, style: { backgroundColor: 'gold', textColor: '#000000' } },
    { option: 'Five Free Rides', prizeKey: 'five_free_rides', probability: 0.02, style: { backgroundColor: '#FF5733', textColor: '#FFFFFF' } },
    { option: '1,000 Coins', prizeKey: 'coins_1000', probability: 0.3, style: { backgroundColor: '#33FF57', textColor: '#000000' } },
    { option: '1,000,000 Coins', prizeKey: 'coins_1000000', probability: 0.01, style: { backgroundColor: '#FF33A6', textColor: '#FFFFFF' } },
    { option: 'Boost Ability', prizeKey: 'boost_ability', probability: 0.2, style: { backgroundColor: '#5733FF', textColor: '#FFFFFF' } },
    { option: 'Full Energy', prizeKey: 'full_energy', probability: 0.2, style: { backgroundColor: '#FFD700', textColor: '#000000' } },
    { option: 'One Free Spin', prizeKey: 'free_spin', probability: 0.15, style: { backgroundColor: '#33C3FF', textColor: '#FFFFFF' } },
    { option: '5,000 Coins', prizeKey: 'coins_5000', probability: 0.1, style: { backgroundColor: '#FF8C00', textColor: '#FFFFFF' } },
    { option: '30,000 Free Ton', prizeKey: 'free_ton', probability: 0, style: { backgroundColor: '#8B0000', textColor: '#FFFFFF' } },
    { option: 'Nothing', prizeKey: 'nothing', probability: 0.02, style: { backgroundColor: '#808080', textColor: '#FFFFFF' } },
];


import Loading from '../components/Loading'; // Import the Loading component

const WheelPage = () => {
    const userId = localStorage.getItem("userId");
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const walletAddress = useTonAddress();
    const [tonConnectUI] = useTonConnectUI();
    const mainWallet = "UQCJbMWHI_VR41DmHnhJzvxfGaxWpqRZcoMm9yYrdLVfsxgF";

    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [selectedPrize, setSelectedPrize] = useState(null);
    const [energy, setEnergy] = useState(0);
    const [coins, setCoins] = useState(0);
    const [tapLevel, setTapLevel] = useState(1);
    const [energyLevel, setEnergyLevel] = useState(1);
    const [speedLevel, setSpeedLevel] = useState(1);
    const [bot, setBot] = useState(false);
    const [wheelUses, setWheelUses] = useState(3);
    const [skin, setSkin] = useState('Basic');
    const [skins, setSkins] = useState(['Basic']);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [turboUses, setTurboUses] = useState(3);
    const [fullEnergyUses, setFullEnergyUses] = useState(3);
    const [wheelUseTimer, setWheelUseTimer] = useState('Daily Limit');
    const [intervals, setIntervals] = useState([]);
    const [isGiftModalOpen, setIsGiftModalOpen] = useState(false);
    const [giftDetails, setGiftDetails] = useState(null);


    const handleSpinClick = () => {
        closeModal()
        if (!mustSpin) {
            const totalWeight = data.reduce((acc, item) => acc + item.probability, 0);
            const random = Math.random() * totalWeight;

            let cumulativeWeight = 0;
            const newPrizeNumber = data.findIndex((item) => {
                cumulativeWeight += item.probability;
                return random < cumulativeWeight;
            });

            setPrizeNumber(newPrizeNumber);
            setMustSpin(true);
            if (wheelUses === 1) {
                startTimer(new Date(), wheelUseTimer, 6 * 60 * 60 * 1000);
            }
        }
    }

    const startTimer = (wheelUseTimer, setTimer, duration) => {
        const targetTime = new Date(wheelUseTimer).getTime() + duration;
        // const intervalId = setInterval(() => {
        //     const now = new Date().getTime();
        //     const distance = targetTime - now;
        //
        //     if (distance < 0) {
        //         clearInterval(intervalId);
        //         setWheelUseTimer("Available");
        //     } else {
        //         const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        //         const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        //         const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        //         setWheelUseTimer(`${hours}h ${minutes}m ${seconds}s`);
        //     }
        // }, 1000);

        // setIntervals(prevIntervals => [...prevIntervals, intervalId]);
    };

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.BackButton.onClick(() => {
                window.history.back(); // Navigate back in the browser history
            });
        }

        const fetchUserData = async () => {
            try {
                const response = await axios.get(`/api/users/${userId}`);
                if (response.data) {
                    setEnergy(response.data.energy);
                    setCoins(response.data.coins);
                    setTurboUses(response.data.turboUses);
                    setFullEnergyUses(response.data.fullEnergyUses);
                    setTapLevel(response.data.tapLevel);
                    setSpeedLevel(response.data.speedLevel);
                    setEnergyLevel(response.data.energyLevel);
                    setWheelUses(response.data.wheelUses);

                    if (response.data.lastWheelUse) {
                        await startTimer(new Date(response.data.lastWheelUse), setWheelUseTimer, 6 * 60 * 60 * 1000);
                    }
                    // Calculate the time elapsed since lastTapUse and update energy
                    if (response.data.lastTapUse) {
                        const elapsedTime = Math.floor((Date.now() - new Date(response.data.lastTapUse).getTime()) / 1000);
                        const maxEnergy = tapLevel * 500;
                        const additionalEnergy = Math.min(elapsedTime, maxEnergy);
                        setEnergy(prevEnergy => Math.min(prevEnergy + additionalEnergy, maxEnergy));
                    }
                    setUser(response.data);
                }
            } catch (error) {
                console.error('Failed to fetch user data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [userId]);

    const formatNumber = (number) => {
        if (number >= 1000000000) {
            return (number / 1000000000).toFixed(3) + ' B'; // Convert to billions and add 'T'
        } else if (number >= 1000000) {
            return (number / 1000000).toFixed(3) + ' M';
        }
        return new Intl.NumberFormat('en-US').format(number); // Format with thousand separators
    };

    const updateUserData = async (updateFields) => {
        try {
            await axios.put('/api/users', {userId, ...updateFields});
        } catch (error) {
            console.error('Failed to update user data:', error);
        }
    };

    const handlePrizeAction = async (prizeKey) => {
        try {
            let prizeDetails = null;
            switch (prizeKey) {
                case 'coins_1000':
                    toast.success('You won 1,000 Coins!');
                    prizeDetails = {
                        icon: '/icons/coin.png',
                        message: 'You won 1,000 Coins!',
                        action: async () => {
                            setCoins((prevCoins) => prevCoins + 1000);
                            await updateUserData({ coins: coins + 1000 });
                        },
                    };
                    break;

                case 'coins_1000000':
                    toast.success('You won 1,000,000 Coins!');
                    prizeDetails = {
                        icon: '/icons/coin.png',
                        message: 'You won 1,000,000 Coins!',
                        action: async () => {
                            setCoins((prevCoins) => prevCoins + 1000000);
                            await updateUserData({ coins: coins + 1000000 });
                        },
                    };
                    break;

                case 'boost_ability':
                    toast.success('You won an extra Boost Ability!');
                    prizeDetails = {
                        icon: '/icons/boost.png',
                        message: 'You won an extra Boost Ability!',
                        action: async () => {
                            setTurboUses((prevUses) => prevUses + 1);
                            await updateUserData({ turboUses: turboUses + 1 });
                        },
                    };
                    break;

                case 'full_energy':
                    toast.success('Your energy is now full!');
                    prizeDetails = {
                        icon: '/icons/energy.png',
                        message: 'Your energy is now full!',
                        action: async () => {
                            const fullEnergy = energyLevel * 500;
                            setEnergy(fullEnergy);
                            await updateUserData({ energy: fullEnergy });
                        },
                    };
                    break;

                case 'free_spin':
                    toast.success('You got a Free Spin!');
                    prizeDetails = {
                        icon: '/icons/spin.png',
                        message: 'You got a Free Spin!',
                        action: async () => {
                            handleSpinClick();
                        },
                    };
                    break;

                case 'five_free_rides':
                    toast.info('You won Five Free Rides! Enjoy your rides!');
                    return;

                case 'free_ton':
                    toast.info('You won 30,000 Free Ton! Great job!');
                    return;

                case 'cash_10000':
                    toast.success('Congratulations! You won $10,000 Cash!');
                    return;

                case 'coins_5000':
                    toast.success('You won 5,000 Coins!');
                    prizeDetails = {
                        icon: '/icons/coin.png',
                        message: 'You won 5,000 Coins!',
                        action: async () => {
                            setCoins((prevCoins) => prevCoins + 5000);
                            await updateUserData({ coins: coins + 5000 });
                        },
                    };
                    break;

                case 'nothing':
                    toast.info('Better luck next time!');
                    return;

                default:
                    toast.error('Unknown prize!');
                    return;
            }

            // نمایش جزئیات جایزه در مودال
            if (prizeDetails) {
                setGiftDetails(prizeDetails);
                setIsGiftModalOpen(true);
            }
        } catch (error) {
            console.error('Error handling prize:', error);
            toast.error('An error occurred while processing your prize.');
        }
    };


    const handlePurchase = () => {

    }


    const handleModalOpen = (type, cost = 0, icon, onClick = () => handlePurchase(type, cost)) => {
        setIsGiftModalOpen(false);
        let content = {};
        let hasEnoughCoins = false;

        switch (type) {
            case 'PayCoin':
                const fixedCost = 10000; // Fixed cost for PayCoin spin
                hasEnoughCoins = coins >= fixedCost;

                content = {
                    image: `./icons/${icon}`,
                    title: type,
                    modalType: 'PayCoin',
                    text: `Spend ${fixedCost.toLocaleString('en-US')} coins to spin the wheel.`,
                    fee: `${fixedCost.toLocaleString('en-US')} coins`,
                    status: wheelUses > 0 && hasEnoughCoins,
                    timer: !wheelUses > 0
                        ? 'Daily Spin Limit Reached'
                        : hasEnoughCoins
                            ? 'Claim & Spin'
                            : 'Insufficient Coins',
                    onClick: async () => {
                        if (!wheelUses > 0) {
                            toast.error('You have reached your daily spin limit for coins!');
                            return;
                        }

                        if (!hasEnoughCoins) {
                            toast.error('Not enough coins to spin!');
                            return;
                        }

                        // Deduct coins and update the user data
                        setCoins(coins - fixedCost);
                        setWheelUses(wheelUses - 1);

                        await axios.put('/api/users', {
                            boosterType: type,
                            cost: fixedCost,
                            coins: coins - fixedCost,
                            userId,
                            wheelUses: wheelUses,
                        });

                        handleSpinClick();
                    },
                };
                break;

            case 'PayTon':
                hasEnoughCoins = walletAddress;
                content = {
                    image: `./icons/${icon}`,
                    title: type,
                    modalType: 'PayTon',
                    text: walletAddress
                        ? `Click Claim to pay via Ton and spin the wheel.`
                        : `Connect your wallet to proceed.`,
                    fee: `Ton Payment Required`,
                    status: !walletAddress,
                };
                break;

            default:
                toast.error('Type is not correct!');
                break;
        }

        setModalContent(content);
        setIsModalOpen(true);
    };


    const closeModal = () => {
        setIsModalOpen(false);
        setIsGiftModalOpen(false);
    };

    const handlePayTon = async () => {
        try {
            if (!walletAddress) {
                toast.error('Please connect your wallet first!');
                return;
            }

            // Call the withdrawal function
            const result = await handleTonWithdrawal();

            if (result.success) {
                toast.success('Transaction successful! Spinning the wheel...');
                setWheelUses(wheelUses - 1); // Deduct the wheel uses
                handleSpinClick(); // Spin the wheel
            } else {
                toast.error('Transaction failed. Please try again.');
            }
        } catch (error) {
            console.error('Error processing Ton withdrawal:', error);
            toast.error('An error occurred during the transaction.');
        }
    };

    const handleTonWithdrawal = async () => {
        try {
            // Prepare the transaction payload
            const transactionPayload = {
                validUntil: Math.floor(Date.now() / 1000) + 600, // Valid for 10 minutes
                messages: [
                    {
                        address: mainWallet, // Replace with your wallet address
                        amount: (1 * 1e9).toString(), // Sending 1 TON (in nanotons)
                    },
                ],
            };

            // Send the transaction using TonConnectUI
            const result = await tonConnectUI.sendTransaction(transactionPayload);

            if (result) {
                closeModal()
                return { success: true };
            } else {
                return { success: false, error: 'Transaction failed' };
            }
        } catch (error) {
            closeModal()
            console.error('Error during Ton withdrawal:', error);
            return { success: false, error: error.message || 'Unknown error' };
        }
    };


    return (
        <div className="boost">
            {loading ? (
                <Loading/>
            ) : (
                <>
                    <div className="header">
                        <div className="user-info">
                            <div className="coins-display">
                                <span className="coin-icon">
                                    <img src="/icons/coin.png" width="35px" alt='Coin'/>
                                </span>
                                <span className="coin-text">
                                    {formatNumber(coins)}
                                </span>
                            </div>
                            <div className="rank">
                                <span className="rank-icon">
                                    <img src={`/icons/gift.png`} width="30px" alt='Price'/>
                                </span>
                                <span className="rank-text">
                                        {selectedPrize ? `You won: ${selectedPrize}` : 'Spin the wheel!'}
                                </span>
                            </div>
                        </div>
                        <div className="separator"></div>
                        <div className="free-boosts">
                            <div className="free-boosts-buttons">
                                <div
                                    className="free-boost-button m-r"
                                    onClick={() => handleModalOpen('PayCoin', '/icons/golden.png')}
                                    style={{opacity: wheelUses > 0 && coins >= 10000 ? 1 : 0.5}}
                                >
                                    <div className="free-boosts-detail">
                                        <span className="select-none">Pay Coins</span>
                                        <span className="available select-none">
                                            {wheelUses > 0 ? `${wheelUses}/3 available` : `${wheelUseTimer}`}
                                        </span>
                                    </div>
                                    <div>
                                        <img src="/icons/golden.png" width="30px" alt="Coins"/>
                                    </div>
                                </div>

                                <div
                                    className="free-boost-button m-l"
                                    onClick={() => handleModalOpen('PayTon', '/icons/ton.png')}
                                    style={{opacity: walletAddress ? 1 : 0.5}}
                                >
                                    <div className="free-boosts-detail">
                                        <span className="select-none">Pay Ton</span>
                                        <span className="available select-none">
                                            {walletAddress ? 'Send Transaction' : 'Connect Wallet'}
                                        </span>
                                    </div>
                                    <div>
                                        <img src="/icons/ton.png" width="30px" alt="Ton"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content wheel">
                        <Wheel
                            mustStartSpinning={mustSpin}
                            prizeNumber={prizeNumber}
                            data={data}

                            onStopSpinning={() => {
                                setMustSpin(false);
                                const prize = data[prizeNumber];
                                setSelectedPrize(prize.option);
                                handlePrizeAction(prize.prizeKey); // استفاده از prizeKey
                            }}
                        />

                    </div>

                    {isModalOpen && (
                        <div className="modal">
                            <div className="modal-content">
                                {/* Close button */}
                                <button className="close-button" onClick={closeModal}>×</button>

                                <img src={modalContent.modalType === 'PayCoin' ? '/icons/golden.png' : '/icons/ton.png'}
                                     alt={modalContent.modalType === 'PayCoin' ? 'Pay Coin' : 'Pay Ton'} className="modal-image"/>

                                {/* Modal Title */}
                                <h2>{modalContent.modalType === 'PayCoin' ? 'Pay Coin' : 'Pay Ton'}</h2>
                                {/* Modal Description */}
                                <p>
                                    {modalContent.modalType === 'PayCoin'
                                        ? 'Spend 10,000 coins to spin the wheel!'
                                        : modalContent.modalType === 'PayTon'
                                            ? walletAddress
                                                ? 'Click Claim to process Ton payment and spin!'
                                                : 'Connect your wallet to proceed.'
                                            : modalContent.text}
                                </p>

                                <div className="modal-fee">
                                    <img src="/icons/coin.png" width="25px" alt="Fee Icon"/>
                                    <span>{modalContent.fee.toLocaleString('en-US')}</span>
                                </div>

                                {/* Action Button */}
                                {modalContent.modalType === 'PayTon' ? (
                                    !walletAddress ? (
                                        // Show the custom TonConnectButton if the wallet is not connected
                                        <TonConnectButton className="custom-ton-button"/>
                                    ) : (
                                        // Show the Claim & Pay with Ton button if the wallet is connected
                                        <button
                                            onClick={handlePayTon}
                                            className="modal-button"
                                        >
                                            Send Transaction
                                        </button>
                                    )
                                ) : (
                                    // Default button for other modal types
                                    <button
                                        onClick={modalContent.onClick}
                                        className="modal-button"
                                        disabled={!modalContent.status}
                                        style={{
                                            background: !modalContent.status ? 'gray' : '',
                                            cursor: !modalContent.status ? 'not-allowed' : 'pointer',
                                        }}
                                    >
                                        {modalContent.status ? 'Claim & Spin' : modalContent.timer}
                                    </button>
                                )}
                            </div>
                        </div>
                    )}

                    {isGiftModalOpen && giftDetails && (
                        <div className="modal-gift">
                            <div className="modal-content">
                                {/* Close button */}
                                <button className="close-button" onClick={closeModal}>×</button>

                                <img src={giftDetails.icon} alt="Gift Icon"
                                     className="modal-image"/>

                                {/* Modal Title */}
                                <h2>{giftDetails.message}</h2>
                                {/* Modal Description */}
                                <p>
                                    By Click Claim You will get the prize 🙂
                                </p>

                                <div className="modal-fee">

                                </div>

                                <button
                                    className="modal-button claim"
                                    onClick={() => {
                                        giftDetails.action();
                                        setIsGiftModalOpen(false);
                                    }}
                                >
                                    Claim Gift
                                </button>
                            </div>
                        </div>

                    )}
                </>
            )}
        </div>
    );
};

export default WheelPage;
